import React, { useState, useEffect } from "react";
import {getRandomPictureKey} from "../../utils/customisedBookUtility";

function Home({ swapBackground, translator, swapLanguage }) {

  const [ countdown, setCountdown ] = useState({
    d: 0,
    h: 0,
    m: 0,
    s: 0
  });

  useEffect(() => {

    swapBackground("/images/wormhole.jpg");

    let lang = navigator.language.toLowerCase();
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      lang = searchParams.get("language");
    }
    swapLanguage(lang);


    setInterval(() => {

      const now = new Date();
      const eventDate = new Date('2023-05-01');

      const currentTime = now.getTime();
      const eventTime = eventDate.getTime();

      const remTime = eventTime - currentTime;

      let s = Math.floor(remTime / 1000);
      let m = Math.floor(s / 60);
      let h = Math.floor(m / 60);
      let d = Math.floor(h / 24);

      h %= 24;
      m %= 60;
      s %= 60;

      setCountdown({
        d, h, m, s
      })

    }, 1000);

  }, []);


  return (
    <div className="container">
      <br />
      <br />
      <img src="/images/logo.png" className="logo_img" />
      <br />
      <br />
      <h1 className="leader">Flushing here soon on Android, iOS and Web</h1>
      {/*<h2 className="subtitle">Our website is under construction</h2>*/}
      <br />
      <br />
      <div className="countdown">
        <div className="countdown__item">
          <span id="days">{countdown.d} </span>
          <small>Days</small>
        </div>
        <div className="countdown__item">
          <span id="hours">{countdown.h}</span>
          <small>Hours</small>
        </div>
        <div className="countdown__item">
          <span id="minutes">{countdown.m}</span>
          <small>Minutes</small>
        </div>
        <div className="countdown__item">
          <span id="seconds">{countdown.s}</span>
          <small>Seconds</small>
        </div>
      </div>
      <div className="social-icons">
        <a href="#"><i className="fab fa-facebook"></i></a>
        <a href="#"><i className="fab fa-twitter"></i></a>
        <a href="#"><i className="fab fa-instagram"></i></a>
        <a href="#"><i className="fab fa-linkedin"></i></a>
      </div>
    </div>

  );
}

export default Home;