import { Link } from "react-router-dom";

function Header() {
  return (null)

    // <header className="bg-gray-900 text-white p-4">
    //   <h1 className="text-2xl">Time Travelling Toilet</h1>
    // </header>
}

export default Header;