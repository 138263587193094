import styles from "./Environment.module.css"

function Environment() {

  if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
    return null;
  }

  return (
    <div className={styles.environment}>{process.env.REACT_APP_ENVIRONMENT}</div>
  )
}

export default Environment