import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getTranslations } from "./services/translationService";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Builder from "./pages/Builder";
import Home from "./pages/Home";
import Environment from "./components/Environment";
import Product from "./pages/Product";
import Products from "./pages/Products";
import AuthRoute from "./components/AuthRoute";
import Customer from "./pages/Customer";
import Preview from "./pages/Preview";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Signout from "./components/Signout";
import ForgottenPassword from "./pages/ForgottenPassword";
import ForgottenPasswordVerificationCode from "./pages/ForgottenPasswordVerificationCode";
import { getTranslator } from "./utils/translatorUtility";

import "./App.css";

function App() {
  const [styles, setStyles] = useState({
    backgroundImage: null,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  });

  const [language, setLanguage] = useState("ko-kr");
  const [translations, setTranslations] = useState([]);

  const translator = getTranslator(translations, language);

  const handleTranslations = async () => {
    const t = await getTranslations();
    setTranslations(t);
  };

  const swapLanguage = (language) => {
    setLanguage(language);
  };

  useEffect(() => {
    let lang = navigator.language.toLowerCase();
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("language")) {
      lang = searchParams.get("language");
    }
    setLanguage(lang);

    if (process.env.REACT_APP_ENVIRONMENT !==  "PRODUCTION") {
      handleTranslations();
    }

  }, []);

  const swapBackground = (background) => {
    setStyles({
      ...styles,
      backgroundImage: `url("${background}")`,
    });
  };

  const MINIMAL_ROUTES = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";

  if (MINIMAL_ROUTES) {
    return (
      <div className="App" style={styles}>
        <Router>
          <Header></Header>
          <main className="flex flex-col justify-center items-center">
            <Routes>
              <Route
                path="*"
                element={
                  <Home
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
            </Routes>
          </main>
          <Footer />
        </Router>
        <Environment />
      </div>
    );
  } else {
    return (
      <div className="App" style={styles}>
        <Router>
          <Header></Header>
          <main className="flex flex-col justify-center items-center">
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:puuid" element={<Product />} />
              <Route
                path="/products/:puuid/builder/:step"
                element={<Builder />}
              />
              <Route
                path="/sign-up"
                element={
                  <Signup
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
              <Route
                path="/sign-in"
                element={
                  <Signin
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
              <Route
                path="/forgotten-password"
                element={
                  <ForgottenPassword
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
              <Route
                path="/forgotten-password-verification-code"
                element={
                  <ForgottenPasswordVerificationCode
                    swapLanguage={swapLanguage}
                    language={language}
                    swapBackground={swapBackground}
                    translator={translator}
                  />
                }
              />
              <Route
                path="/customer/:cuuid"
                element={
                  <AuthRoute>
                    <Customer swapBackground={swapBackground} />
                  </AuthRoute>
                }
              />
              <Route
                path="/customer/:cuuid/products/:puuid/builder/:step"
                element={
                  <AuthRoute>
                    <Builder />
                  </AuthRoute>
                }
              />
              <Route path="/drafts/:duuid/preview" element={<Preview />} />
              <Route path="/sign-out" element={<Signout />} />
            </Routes>
          </main>
          <Footer />
        </Router>
        <Environment />
      </div>
    );
  }
}

export default App;
