import React, { useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import styles from "./Preview.module.css"

function Preview() {

  return (
    <div className={styles.preview}>
      <p>Preview</p>
    </div>
  );
}

export default Preview;
